<template>
    <div>
        <div class="wtpl_subtitle_2 d-flex align-items-center justify-content-center">
        <div>ШАБЛОНЫ</div>
        </div>

        <div class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <span><b>Событие</b></span>
        </div>
        <div>
            <select name="profile" class="form-select" v-model="selected_event" @change="toggleDocumentStatuses">
            <option v-for="(t, i) in events" :value="i"><span v-if="t.active">🟢</span><span v-if="!t.active">⚪</span> {{ t.name}}</option>
            </select>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">Выберите событие для настройки его шаблона</span>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
          <div class="d-flex template_buttons_ms">
            <div v-for="(template, i) in this.events[this.selected_event].dataInstance">
            <div :class="{'active': selected_template == i}" class="selected_template_button" @click="selected_template = i">Шаблон {{ i + 1 }}</div>
            </div>
            <div class="selected_template_add">
              <a v-if="this.events[this.selected_event].dataInstance.length < 20" href="#" @click.prevent="addTemplate()" style="margin-top:3px; display:block;">Добавить шаблон</a>
              <span v-if="this.events[this.selected_event].dataInstance.length > 19" style="color: #D3D3D3;margin-top:3px; display:block;">Добавить шаблон</span>
            </div>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <span><b>Статус шаблона</b></span>
        </div>
        <div class="d-flex align-items-center">
            <switch-toggle :responder="null" :uid="'222'" :model.sync="events[selected_event].dataInstance[selected_template].active" :key="selected_uuid + '.' + events[selected_event].type + '.' + events[selected_event].value + '.' + key_iter + '.' + selected_template"
            @toggler="toggleStatus" style="display: inline-block;"></switch-toggle>
            <div v-if="events[selected_event].dataInstance[selected_template].active" class="bot_mini_text always_visible d-inline-block initial active">
                ВКЛЮЧЕН</div>
            <div v-if="!events[selected_event].dataInstance[selected_template].active" class="bot_mini_text always_visible d-inline-block initial">
                ВЫКЛЮЧЕН</div>
        </div>
        </div>

        <div v-if="events.length > 0 && events[selected_event].type == 'UPDATE'" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <b>Параметры события</b>
        </div>
        <div>
            <multiselect v-model="events[selected_event].dataInstance[selected_template].onFieldsData" label="name"
                track-by="code" placeholder="Выберите параметр" :options="events[selected_event].onFieldsParams" :multiple="true" :taggable="true" :hide-selected="true">
                <span slot="noResult" style="display: none;"></span>
                <span slot="noOptions">
                Нет доступных параметров
                </span>
            </multiselect>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">Выберите параметры, на изменение которых будет отправлено уведомление</span>
        </div>
        </div>

        <div v-if="events.length > 0 && events[selected_event].type == 'UPDATE' && showDocumentStatuses" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <b>Статусы документа</b>
        </div>
        <div>
            <multiselect v-model="events[selected_event].dataInstance[selected_template].stateListData" label="name"
                track-by="code" placeholder="Выберите статус" :options="events[selected_event].stateListParams" :multiple="true" :taggable="true" :hide-selected="true">
                <span slot="noResult" style="display: none;"></span>
                <span slot="noOptions">
                Нет доступных статусов
                </span>
            </multiselect>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">Выберите параметры, на изменение которых будет отправлено уведомление</span>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
          <div class="form-label d-flex justify-content-between align-items-center">
              <b>Дополнительные параметры события</b>
          </div>
          <div v-for="(paramData, p) in events[selected_event].dataInstance[selected_template].additionalParamData" class="row mb-2 gx-2 gx-md-4">
            <div class="col-4">
              <select class="form-select" :key="'select_adp_' + p" v-model="events[selected_event].dataInstance[selected_template].additionalParamData[p].code">
                <option v-for="(t, i) in events[selected_event].additionalParams" :value="t.code">{{ t.name}}</option>
              </select>
            </div>
            <div class="col-7">
              <input v-model="events[selected_event].dataInstance[selected_template].additionalParamData[p].value" type="text" name="name" class="form-control" placeholder="Значение поля равно">
            </div>
            <div class="col-1 d-flex align-items-center justify-contant-center"><button @click.prevent="removeAdditionalParam(p)" type="button" aria-label="Удалить параметр" style="display: flex;color:#ccc;border:none;background: none;font-size: 28px;padding: 0;color: #9d9d9d;">×</button></div>
          </div>
          <div class="selected_template_add"><a v-if="events[selected_event].dataInstance.length <= 20" href="#" @click.prevent="addAdditionalParam()" style="margin-top:12px; display:block;color:#6C757D;">Добавить параметр</a></div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <b>Получатель</b>
        </div>
        <div>
            <input :class="{'is-invalid': phone_error != ''}" type="text" name="name" class="form-control" placeholder="Можно ввести несколько получателей через запятую" v-model="events[selected_event].dataInstance[selected_template].recipient" id="reciever_input">
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <span class="input_desc">
            <div v-if="phone_error != ''"><span style="color: red;">{{ phone_error }}</span></div>
            <div v-for="(rval, rkey) in events[selected_event].recipientParams.fields"><b>{{ rval }}:</b> <a class="crm_var" href="#" @click.prevent="insertTextAtCursor(rkey)">{{ rkey }}</a></div>
            <div><b>номер телефона:</b> 79005551213</div>
            </span>
        </div>
        </div>

        <div v-if="events.length > 0" class="responder_head_margin">
        <div class="form-label d-flex justify-content-between align-items-center">
            <span><b>Текст сообщения</b></span>
        </div>

        <crm-textarea :placeholder="'Введите текст шаблона'" :key="events[selected_event].type + '.' + events[selected_event].value + '.' + selected_template"
        :uid="events[selected_event].type + '_' + events[selected_event].value + '_' + selected_template" :text.sync="events[selected_event].dataInstance[selected_template].textData" :text_variables.sync="events[selected_event].textParams"></crm-textarea>

        </div>

        <div class="responder_head_margin responder_submit_buttons">
        <a v-if="!moysklad_sending" @click="submit_button" class="resp_button_green" :class="{'disable': !moysklad_edited_content}">Сохранить</a>
        <a v-if="moysklad_sending" class="resp_button_green disable"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></a>
        <a v-if="events.length > 0 && events[selected_event].dataInstance.length > 1" @click="reset_button(selected_template)" class="resp_button_red ms-2">Удалить шаблон</a>
        </div>
    </div>
          
  </template>
  <script>
  import SwitchToggle from './SwitchToggle.vue';
  import CrmTextarea from './CRMTextarea.vue';
  
  export default {
    data() {
      return {
          selected_uuid: this.current_profile.uuid,
          selected_event: 0,
          selected_template: 0,
          main_text: '',
          reciever: '',
          profile: null,
          events: [],
          moysklad_edited_content: false,
          moysklad_sending: false,
          initialLoad: true,
          key_iter: 0,
          phone_error: "",
          showDocumentStatuses: false,
      };
    },
    methods: {
      addTemplate() {
        if (this.events[this.selected_event].dataInstance.length > 20) {
          return;
        }
        this.events[this.selected_event].dataInstance.push({
          active: false,
          textData: "",
          recipient: "",
          onFieldsData: [],
          stateListData: [],
          additionalParamData: [],
        });
        this.selected_template = this.events[this.selected_event].dataInstance.length - 1;
        this.moysklad_edited_content = true;
      },
      addAdditionalParam() {
        this.events[this.selected_event].dataInstance[this.selected_template].additionalParamData.push(JSON.parse(JSON.stringify(this.events[this.selected_event].additionalParams[0])));
      },
      removeAdditionalParam(index) {
        this.events[this.selected_event].dataInstance[this.selected_template].additionalParamData.splice(index, 1);
      },
      reset_button(selected_template) {
        this.$swal.fire({
          title: 'Удалить шаблон?',
          text: "Вы не сможете отменить это действие!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#FC5A5A',
          cancelButtonColor: '#23BB86',
          confirmButtonText: 'Да, удалить!',
          cancelButtonText: 'Отмена'
        }).then((result) => {
          if (result.isConfirmed) {
            this.events[this.selected_event].dataInstance.splice(selected_template, 1);
            this.selected_template = 0;
            this.moysklad_edited_content = true;
          }
        });  
      },
      toggleDocumentStatuses(is_watcher) {
        if (!is_watcher) {
          this.selected_template = 0;
        }
        console.log(this.events[this.selected_event].dataInstance[this.selected_template].onFieldsData);
        if (this.events[this.selected_event].dataInstance[this.selected_template].onFieldsData.filter(d => d.code == "{state}").length > 0) {
          this.showDocumentStatuses = true;
        } else {
          this.showDocumentStatuses = false;
        }
      },
      toggleStatus(profile_button) {  
        this.phone_error = "";
        if (this.$store.state.user.moysklad && !profile_button) {
          let enabledTemplates = this.events[this.selected_event].dataInstance.filter(e => e.active);
          if (enabledTemplates.length > 0) {
            this.events[this.selected_event].active = true;
            this.moysklad_edited_content = true;
          } else {
            this.events[this.selected_event].active = false;
            this.moysklad_edited_content = true;
          }
        }
      },
      insertTextAtCursor(text) {
        let input = document.querySelector('#reciever_input');
        // console.log(input);
        // Проверяем, есть ли поддержка selectionStart (современные браузеры поддерживают это)
        if (input.selectionStart || input.selectionStart === 0) {
            // Запоминаем позицию курсора
            let startPos = input.selectionStart;
            let endPos = input.selectionEnd;
  
            // Вставляем текст и обновляем свойство value элемента input
            input.value = input.value.substring(0, startPos) +
                            text +
                            input.value.substring(endPos, input.value.length);
  
            // Перемещаем курсор в конец вставленного текста
            input.selectionStart = input.selectionEnd = startPos + text.length;
        } else {
            // Просто добавляем текст в конец, если нет поддержки selectionStart
            input.value += text;
        }
  
        this.events[this.selected_event].dataInstance[this.selected_template].recipient = input.value;
  
        // Возвращаем фокус элементу input
        input.focus();
      },
      submit_button() {
        this.phone_error = "";
        if (!this.moysklad_edited_content) {
            return;
        }
        this.moysklad_sending = true;
        this.$axios.post(
            "https://" +
            window.location.host +
            "/mysklad/getform/submit?profile_id=" + this.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad,
        {
            events: this.events,
        },
        {
            headers: {
            Authorization: this.$store.state.token,
            Token: this.$store.state.user.moysklad_token,
            },
        }
        )
        .then(response => {
        this.moysklad_sending = false;
        this.moysklad_edited_content = false;
        this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Данные сохранены",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
            });
        })
        .catch((error) => {
        if ('errorField' in error.response.data) {
            console.log('Ключ errorField существует');
            if (error.response.data.errorField == 'phone') {
                this.phone_error = error.response.data.detail;
            }
        } else {
            console.log('Ключ errorField не существует');
        }
        this.moysklad_sending = false;
        this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "warning",
            title: "Ошибка сохранения данных",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 2000,
            });
        });
      },
    },
    watch: {
        events: {
            handler: function(newValue) {
              if (this.initialLoad) {
                this.initialLoad = false;
                return;
              }
              this.toggleDocumentStatuses(true);
              console.log('я изменилось');
              this.moysklad_edited_content = true;
            },
            deep: true
        }
    },
    props: ["current_profile", "crm_type"],
    created() {
      // console.log(this.profile);
    },
    mounted(){
      
        this.$axios.get(
            "https://" +
            window.location.host +
            "/mysklad/getJsonData?profile_id=" + this.current_profile.uuid + "&moysklad=" + this.$store.state.user.moysklad,
            {
              headers: {
                Authorization: this.$store.state.token,
                Token: this.$store.state.user.moysklad_token,
              },
            }
          )
          .then(response => {
            console.log(response.data);
            
            this.events = response.data.events;
            this.toggleDocumentStatuses();
            // this.events.forEach((e) => {
            //   e.onFieldsParams.forEach((f) => {
            //     f.name = f.name.replace('}', '');
            //   });
            // });
          })
          .catch((error) => {
            // console.log(error);
            this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "warning",
                title: "Ошибка получения данных",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000,
              });
            });
        
  
        const sidebar = document.querySelector(".sidebar");
        if(window.screen.width < 768){
          sidebar.classList.add("sidebar-mini");
        }
        let Scrollbar;
        if ($(".data-scrollbar").length) {
  
          let scr = document.querySelector('.data-scrollbar');
  
          Scrollbar = window.Scrollbar;
          let scrollbar = Scrollbar.init(scr, {
            continuousScrolling: true,
          });
  
          window.addEventListener("wheel", event => {
            if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
              localStorage['transform'] = scrollbar.scrollTop;
              // console.log(localStorage['transform']);
            }
          });
  
          if (localStorage['transform']) {
            // console.log(localStorage['transform']);
            scrollbar.scrollTop = localStorage['transform'];
          }
        }
      
    },
    components: {
        SwitchToggle,
        CrmTextarea,
    },
  };
  </script>
  