<template>
    <span @click="fillText" class="wabavar" :class="{'wabavar_contains': status}">
        <span class="me-1">{{ text }}</span>
        <span>
          <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.38198 4.47736L2.09918 1.19456C1.80054 0.895922 1.31636 0.895922 1.01772 1.19456C0.719085 1.49319 0.719085 1.97738 1.01772 2.27602L4.84125 6.09955C5.13989 6.39818 5.62407 6.39818 5.92271 6.09955L9.74624 2.27602C10.0449 1.97738 10.0449 1.49319 9.74624 1.19456C9.4476 0.895922 8.96342 0.895922 8.66478 1.19456L5.38198 4.47736Z" fill="#232D42"/>
          </svg>
        </span>
        <input v-if="status" type="hidden" name="wabavar[]" :value="text">
      </span>
</template>

<script>
export default {
  data() {
    return {
      status: false,
      text: "заполнить",
    };
  },
  props: ["number"],
  methods: {
    async fillText() {
      const { value: formValue } = await this.$swal.fire({
        title: 'Заполните текст',
        // title: 'Заполнить переменную ' + this.number,
        html: '<input id="wabavar_'+ this.number +'" class="swal2-input" value="'+this.text+'" style="font-size: 1rem;width: 340px;">',
        confirmButtonColor: "#1ea276",
        focusConfirm: false,
        preConfirm: () => {
          return document.getElementById('wabavar_'+ this.number).value
        }
      })

      if (formValue && formValue !== "" && formValue !== " ") {
        this.text = formValue;
        this.status = true;
      }
    },
  },
}
</script>