<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                ">
                <div>
                  <h1 class="c-content">Каскады</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row g-0 g-sm-2 g-md-4">
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="mass_title responder_head_margin">{{ cascade.name }}</h2>

                  <div class="responder_head_margin">
                    <div class="info-block d-flex flex-wrap align-items-center">
                      <span class="c-title">ID каскада:</span>
                      <span class="c-content ms-2">{{ cascade.cascade_id }}</span>
                      <span class="c-ico d-none d-sm-inline" data-toggle="tooltip" title="Копировать в буфер"><img
                          src="/static/images/copy.svg" alt="edit" class="img-fluid" @click="copyUuid" /></span>
                    </div>
                  </div>
                  
                  <div class="responder_head_margin">
                    <div class="form-label d-flex justify-content-between align-items-center">
                      <b>Название</b>
                    </div>
                    <div>
                      <input type="text" name="name" class="form-control" v-model="cascade.name">
                    </div>
                  </div>

                  <div class="wtpl_subtitle_2 d-flex align-items-center justify-content-center">
                    <div>Добавьте каналы в нужном порядке</div>
                  </div>

                  <div v-for="(order, i) in cascade.order" class="responder_head_margin" style="background: #F3FFF6;padding: 24px;margin-bottom: 26px;">
                    <div class="d-flex justify-content-between mb-2" style="color: #6C757D; font-weight: bold">
                      <div>{{ i+1 }}. {{ getTitleByPlatform(order.platform) }}</div>
                      <svg @click="order_delete(i)" style="cursor: pointer;" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L7.5 6.32149L13.5774 0.244078C13.9028 -0.0813592 14.4305 -0.0813592 14.7559 0.244078C15.0814 0.569515 15.0814 1.09715 14.7559 1.42259L8.67851 7.5L14.7559 13.5774C15.0814 13.9029 15.0814 14.4305 14.7559 14.7559C14.4305 15.0814 13.9028 15.0814 13.5774 14.7559L7.5 8.67851L1.42259 14.7559C1.09715 15.0814 0.569515 15.0814 0.244078 14.7559C-0.0813592 14.4305 -0.0813592 13.9029 0.244078 13.5774L6.32149 7.5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z" fill="#232D42"/>
                      </svg>
                    </div>
                    <div>
                      <div class="d-flex justify-content-between align-items-center">
                        <span class="input_desc" style="margin-left: 1px; margin-bottom: 2px;">Выберите профиль</span>
                      </div>
                      <select v-model="order.profile_uuid" class="form-select" @change="select_profile($event, i)">
                        <option v-for="profile in profiles" :value="profile.uuid">{{ profile.name }} ({{ get_platform(profile.platform) }})</option>
                      </select>
                    </div>
                  </div>

                  <a @click.prevent="order_add" class="responder_add mb-5">
                    <svg class="mr16" width="20" height="24" viewBox="0 0 20 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <g id="plus-solid 1">
                      <path id="Vector" d="M18.5714 9.75H12.1429V3C12.1429 2.17172 11.5031 1.5 10.7143 1.5H9.28571C8.49688 1.5 7.85714 2.17172 7.85714 3V9.75H1.42857C0.639732 9.75 0 10.4217 0 11.25V12.75C0 13.5783 0.639732 14.25 1.42857 14.25H7.85714V21C7.85714 21.8283 8.49688 22.5 9.28571 22.5H10.7143C11.5031 22.5 12.1429 21.8283 12.1429 21V14.25H18.5714C19.3603 14.25 20 13.5783 20 12.75V11.25C20 10.4217 19.3603 9.75 18.5714 9.75Z" fill="currentColor"/>
                    </g>
                    </svg>
                    <span>Добавить канал</span>
                  </a>
                  
                  <div class="responder_head_margin responder_submit_buttons">
                    <button v-if="!sending" class="resp_button_green mr24" @click="save_cascade()">Сохранить</button>
                    <button v-if="sending" class="resp_button_green mr24" disabled><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></button>
                    <inertia-link class="resp_button_grey2" :href="route('cascade.index')">Отменить</inertia-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card card_desc">
                <div class="card-body">
                  <h2 class="mass_title mb-3">Каскады - как это работает?</h2>
                  <div class="mb-4">
                    <p>С помощью каскадов вы можете организовать рассылку сообщений в разные каналы - Whatsapp, Telegram и СМС.  </p>
                    <p>При каскадной рассылке вы можете задать последовательность каналов. Если очередной канал не позволяет доставить сообщение абоненту, то система отправляет его на следующий.</p>
                    <p>Использование каскадов в рассылке позволяет повысить вероятность, что сообщение дойдет до адресата. Если у клиента нет одного канала, то сообщение уйдет по другому. Идеально подходит для автоматических уведомлений и сервисных сообщений.</p>
                    <p>Вы можете использовать каскады для отправки сообщений по API, в нашем сервисе Рассылки сообщений или в любой нашей интеграции, выбрав нужный каскад как обычный профиль.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer></Footer>
      </div>

    </main>
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import Footer from "./Components/Footer.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';


export default {
  data() {
    return {
      sending: false,
    };
  },
  props: ["token", "user", "profiles", "cascade"],
  methods: {
    get_platform(pl) {
      if (pl == 'wz') {
        return 'Whatsapp';
      } else if (pl == 'tg') {
        return 'Telegram';
      } else if (pl == 'sms') {
        return 'SMS';
      }
    },
    order_delete(index) {
      this.cascade.order.splice(index, 1);
    },
    order_add() {
      this.cascade.order.push({
        platform: this.profiles[0].platform,
        profile_uuid: this.profiles[0].uuid,
      });
    },  
    select_profile(e, index) {
      let profile = this.profiles.find(p => p.uuid === e.target.value);
      this.cascade.order[index].platform = profile.platform;
    },
    getTitleByPlatform(platform) {
      if (platform === 'tg') {
        return 'Telegram';
      } else if (platform === 'wz') {
        return 'Whatsapp';
      } else if (platform === 'sms') {
        return 'SMS';
      } else {
        return 'Unknown';
      }
    },
    save_cascade() {
      this.sending = true;
      this.$axios
        .post(
          "https://" + window.location.host + "/csender/cascade/update?user_id=" + this.user.id,
          this.cascade,
          {
            headers: {
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          this.sending = false;
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Каскад сохранен",
            timerProgressBar: true,
            showConfirmButton: false,
            timer: 3000,
          });
          this.$inertia.visit(this.route("cascade.index")).then(()=> {
            window.scrollTo(0, 0);
          });
        });
    },
    async copyToClipboard(textToCopy) {
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    async copyUuid() {
      this.copyToClipboard(this.cascade.cascade_id).then(() => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "ID каскада был скопирован",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 2000,
        });
      });
    },
    toggleStatus() {
      console.log(this.gpt);
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          } 
          
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            this.$swal.close();
          }
          // if (data.data === 'open') {
          //   let restore_on_open = document.querySelectorAll('.restore_on_open');
          //   restore_on_open.forEach(res => {
          //     res.classList.remove('disabled')
          //   })
          // }
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
    console.log(this.cascade);
  },
  mounted() {
    const sidebar = document.querySelector(".sidebar");
    if (window.screen.width < 768) {
      sidebar.classList.add("sidebar-mini");
    }
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  components: {
    Sidebar,
    Topbar,
    Footer
  },
};
</script>