<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" all_profiles="all_profiles" />
    <main class="main-content">
      <Topbar @side_toggle="sidebar_toggle" />
      <div class="iq-navbar-header">
        <div class="container-fluid iq-container">
          <div class="row">
            <div class="col-md-12">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <div>
                  <h1 class="c-content">Каскады</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="iq-header-img"></div>
        <div class="container-fluid content-inner py-0 my-mt-5">
          <!--      account block begin-->
          <div class="row g-0 g-sm-2 g-md-4">
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h2 class="mass_title mb16">Ваши каскады</h2>
                  <div v-for="cascade in cascades" class="responder mb24 resp_active">
                    <div class="bot_icon">
                      <svg width="95" height="79" viewBox="0 0 95 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M88.3632 25.8817H82.3754V19.8936C82.3754 16.234 79.3981 13.2568 75.7386 13.2568H69.7504V7.26915C69.7504 3.59755 66.7718 0.632324 63.114 0.632324H6.63683C2.98638 0.63251 0 3.58827 0 7.26915V46.4811C0 50.1407 2.97729 53.118 6.63683 53.118H12.6246V59.1061C12.6246 62.7657 15.6019 65.743 19.2614 65.743H25.2492V71.7307C25.2492 75.3903 28.2265 78.3676 31.886 78.3676H88.3634C92.0229 78.3676 95.0002 75.3903 95.0002 71.7307V32.5184C95 28.859 92.0227 25.8817 88.3632 25.8817ZM58.3233 7.25987L34.8754 30.2393L11.428 7.26043L58.3233 7.25987ZM6.62792 46.4811L6.62922 11.8375L32.5559 37.2461C33.8445 38.5091 35.9067 38.5088 37.1949 37.2461L63.1217 11.8373L63.1143 46.4901L6.62792 46.4811ZM19.2525 59.1058V53.118H63.1143C66.7739 53.118 69.7508 50.1409 69.7508 46.4811V19.8858L75.7475 19.8876C75.7475 19.8876 75.7477 19.8897 75.7477 19.8938L75.7387 59.1147L19.2525 59.1058ZM88.3632 71.7393L31.8771 71.7304V65.7426H75.7389C79.3985 65.7426 82.3758 62.7653 82.3758 59.1058V32.5106L88.3723 32.5123C88.3723 32.5123 88.3725 32.5143 88.3725 32.5184L88.3632 71.7393Z" fill="#23BB86"/>
                      </svg>
                    </div>
                    <div class="bot_content" style="margin-left: 22px;">
                      <div class="bot_name">{{ cascade.name }}</div>
                      <div class="bot_profile d-flex align-items-center">
                        <div v-for="(order, i) in cascade.order" class="d-flex align-items-center">
                          <span v-if="i > 0" class="c-content bold ms-1 me-1">></span>
                          <span><a :href="'/dashboard/' + order.profile_uuid">{{ getTitleByPlatform(order.platform) }}</a></span>
                        </div>
                      </div>
                      <div>
                        <a class="bot_green mr24" :href="'/cascade/' +cascade.cascade_id" style="color:#6C757D">Редактировать</a>
                      </div>
                    </div>
                    <div class="resp_status">
                      <svg @click="cascade_delete(cascade.cascade_id)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L7.5 6.32149L13.5774 0.244078C13.9028 -0.0813592 14.4305 -0.0813592 14.7559 0.244078C15.0814 0.569515 15.0814 1.09715 14.7559 1.42259L8.67851 7.5L14.7559 13.5774C15.0814 13.9029 15.0814 14.4305 14.7559 14.7559C14.4305 15.0814 13.9028 15.0814 13.5774 14.7559L7.5 8.67851L1.42259 14.7559C1.09715 15.0814 0.569515 15.0814 0.244078 14.7559C-0.0813592 14.4305 -0.0813592 13.9029 0.244078 13.5774L6.32149 7.5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z" fill="#232D42"/>
                      </svg>
                    </div>
                  </div>
                  <a href="/cascade/new" class="responder_add">
                    <svg class="mr16" width="20" height="24" viewBox="0 0 20 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <g id="plus-solid 1">
                      <path id="Vector" d="M18.5714 9.75H12.1429V3C12.1429 2.17172 11.5031 1.5 10.7143 1.5H9.28571C8.49688 1.5 7.85714 2.17172 7.85714 3V9.75H1.42857C0.639732 9.75 0 10.4217 0 11.25V12.75C0 13.5783 0.639732 14.25 1.42857 14.25H7.85714V21C7.85714 21.8283 8.49688 22.5 9.28571 22.5H10.7143C11.5031 22.5 12.1429 21.8283 12.1429 21V14.25H18.5714C19.3603 14.25 20 13.5783 20 12.75V11.25C20 10.4217 19.3603 9.75 18.5714 9.75Z" fill="currentColor"/>
                    </g>
                    </svg>
                    <span>Добавить каскад</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <div class="card card_desc">
                <div class="card-body">
                  <h2 class="mass_title mb-3">Каскады - как это работает?</h2>
                  <div class="mb-4">
                    <p>С помощью каскадов вы можете организовать рассылку сообщений в разные каналы - Whatsapp, Telegram и СМС.  </p>
                    <p>При каскадной рассылке вы можете задать последовательность каналов. Если очередной канал не позволяет доставить сообщение абоненту, то система отправляет его на следующий.</p>
                    <p>Использование каскадов в рассылке позволяет повысить вероятность, что сообщение дойдет до адресата. Если у клиента нет одного канала, то сообщение уйдет по другому. Идеально подходит для автоматических уведомлений и сервисных сообщений.</p>
                    <p>Вы можете использовать каскады для отправки сообщений по API, в нашем сервисе Рассылки сообщений или в любой нашей интеграции, выбрав нужный каскад как обычный профиль.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <Footer></Footer>
      </div>
      <!-- 
        <div v-for="responder in responders" class="responder mb24" :class="{'resp_active': gpt.enabled}">
          <div class="mb24">
            <span class="resp_title mr24">{{ responder.name }}</span>
            <span class="resp_profile"><a :href="'/dashboard/' + responder.profile.uuid">{{ responder.profile.name }}</a></span>
          </div>
          <div>
            <a class="resp_button_green mr24" :href="'/responder/' + responder.id">Редактировать</a>
            <a class="resp_button_grey" :href="'/responder/delete/' + responder.id">Удалить</a>
          </div>
          <div v-if="gpt.enabled" class="resp_status active">ВКЛ<span>ЮЧЕН</span></div>
          <div v-if="!gpt.enabled" class="resp_status">ВЫКЛ<span>ЮЧЕН</span></div>
        </div>
       -->
    </main>
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Topbar from "./Components/Topbar.vue";
import Footer from "./Components/Footer.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';
import SwitchToggle from './Components/SwitchToggle.vue';

export default {
  data() {
    return {
        
    };
  },
  methods: {
    getTitleByPlatform(platform) {
      if (platform === 'tg') {
        return 'Telegram';
      } else if (platform === 'wz') {
        return 'Whatsapp';
      } else if (platform === 'sms') {
        return 'SMS';
      } else {
        return 'Unknown';
      }
    },
    cascade_delete(cascade_id) {
      this.$swal
        .fire({
          title: "Вы уверены?",
          text: "При удалении каскада его история рассылок будет удалена!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Не удалять",
          confirmButtonColor: "#d33",
          cancelButtonColor: "#23BB86",
          confirmButtonText: "Удалить",
          customClass: {
            container: this.$store.state.user.moysklad ? 'custom-swal-container' : '',
            popup: this.$store.state.user.moysklad ? 'custom-swal' : '' // Применение кастомного класса
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios.post(
                "https://" +
                window.location.host +
                "/csender/cascade/delete?user_id=" + this.user.id + "&cascade_id=" + cascade_id, {},
                {
                  headers: {
                    Authorization: this.$store.state.token,
                  },
                }
              ).then(response => {
                let index = this.cascades.findIndex(cascades => cascades.cascade_id === cascade_id);
                this.cascades.splice(index, 1);
                this.$swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: "success",
                  title: "Каскад удален",
                  timerProgressBar: true,
                  showConfirmButton: false,
                  timer: 3000,
                });
              }).catch(error => {
                this.$swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: error.response.data.error,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    width: 400,
                    timer: 2000,
                  });
              });
            }
        });
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            // console.log(data.data);
            QRCode.toDataURL(data.data)
            .then(url => {
              this.base64_qr = url;
            });
          }

        } else if (data.method === "update_auth") {
          
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
            } else {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            }
          } 
          
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "qr_timeout") {
          this.base64_qr = '';
          this.is_task_exist = false;
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            this.$swal.close();
          }
         
          if (data.data === 'connecting') {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        }
      };
    },
  },
  props: ["token", "user", "cascades"],
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_user", this.user);
    this.createWebSocketConnection();
  },
  mounted(){
    const sidebar = document.querySelector(".sidebar");
    if(window.screen.width < 768){
      sidebar.classList.add("sidebar-mini");
    }
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }
  },
  components: {
    Sidebar,
    Topbar,
    SwitchToggle,
    Footer
  },
};
</script>
