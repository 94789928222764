var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "wabavar",
      class: { wabavar_contains: _vm.status },
      on: { click: _vm.fillText },
    },
    [
      _c("span", { staticClass: "me-1" }, [_vm._v(_vm._s(_vm.text))]),
      _vm._v(" "),
      _c("span", [
        _c(
          "svg",
          {
            attrs: {
              width: "10",
              height: "7",
              viewBox: "0 0 10 7",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M5.38198 4.47736L2.09918 1.19456C1.80054 0.895922 1.31636 0.895922 1.01772 1.19456C0.719085 1.49319 0.719085 1.97738 1.01772 2.27602L4.84125 6.09955C5.13989 6.39818 5.62407 6.39818 5.92271 6.09955L9.74624 2.27602C10.0449 1.97738 10.0449 1.49319 9.74624 1.19456C9.4476 0.895922 8.96342 0.895922 8.66478 1.19456L5.38198 4.47736Z",
                fill: "#232D42",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.status
        ? _c("input", {
            attrs: { type: "hidden", name: "wabavar[]" },
            domProps: { value: _vm.text },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }