var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iq-navbar-header" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "iq-header-img" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "container-fluid content-inner py-0 my-mt-5",
          style: this.$store.state.user.moysklad
            ? "min-height:initial !important"
            : "",
        },
        [
          _c("div", { staticClass: "row g-0 g-sm-2 g-md-4" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap align-items-center mb-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "position-relative me-3 profile-logo profile-logo1 d-none d-xxl-block",
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "80",
                                height: "80",
                                viewBox: "0 0 80 80",
                                fill: "none",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M40 0C17.91 0 0 17.91 0 40C0 62.09 17.91 80 40 80C62.09 80 80 62.09 80 40C80 17.91 62.09 0 40 0ZM59.6467 27.4033L53.08 58.3367C52.5967 60.53 51.29 61.0633 49.4667 60.03L39.4667 52.6633L34.6467 57.31C34.18 57.91 33.4567 58.2933 32.6467 58.2933C32.64 58.2933 32.6367 58.2933 32.63 58.2933L33.34 48.1133L51.8733 31.3733C52.6733 30.6633 51.6933 30.26 50.63 30.97L27.7333 45.39L17.8667 42.31C15.7333 41.6333 15.6733 40.1767 18.3167 39.13L56.87 24.27C58.6633 23.6167 60.2233 24.6967 59.6433 27.4067L59.6467 27.4033Z",
                                  fill: "#009BDE",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-wrap align-items-center mb-3 mb-sm-0 d-none d-md-block",
                          },
                          [
                            _c("h4", { staticClass: "me-2 h4" }, [
                              _vm._v(_vm._s(_vm.profileName)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-none d-md-block" }, [
                          this.$store.state.current_profile.last_activity !=
                          null
                            ? _c(
                                "span",
                                { staticClass: "c-title last_activity_text" },
                                [
                                  _vm._v(
                                    "Последняя активность\n                    " +
                                      _vm._s(
                                        _vm._f("moment2")(
                                          this.$store.state.current_profile
                                            .last_activity
                                        )
                                      ) +
                                      "\n                    (GMT+03.00, Москва)"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row profile_details" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-6 col-12 profile_details_first" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c("span", { staticClass: "c-title" }, [
                                  _vm._v("Имя:"),
                                ]),
                                _vm._v(" "),
                                !_vm.edited
                                  ? _c(
                                      "span",
                                      { staticClass: "c-content ms-2" },
                                      [_vm._v(_vm._s(_vm.profileName))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edited
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.profileName,
                                          expression: "profileName",
                                        },
                                      ],
                                      staticClass:
                                        "form-control name c-content h- me-1 name_form ms-1 ms-sm-2 me-sm-2",
                                      attrs: {
                                        maxlength: _vm.maxLength,
                                        type: "text",
                                      },
                                      domProps: { value: _vm.profileName },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.saveName.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.profileName = $event.target.value
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edited
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "btn btn-sm btn-success",
                                        on: { click: _vm.saveName },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Сохранить\n                      "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "c-ico" }, [
                                  !_vm.edited
                                    ? _c("img", {
                                        staticClass: "img-fluid",
                                        attrs: {
                                          src: "/static/images/edit.svg",
                                          title: "Редактировать имя",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editName()
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _vm.edited
                                  ? _c(
                                      "span",
                                      { staticClass: "c-content ml-2" },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.profileName.length) +
                                            " / " +
                                            _vm._s(_vm.maxLength)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                this.$store.state.wa_status === "open"
                                  ? _c(
                                      "span",
                                      { staticClass: "c-badge green" },
                                      [_vm._v("open")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                this.$store.state.wa_status === "connecting"
                                  ? _c(
                                      "span",
                                      { staticClass: "c-badge yellow" },
                                      [_vm._v("connecting")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                this.$store.state.wa_status === "close"
                                  ? _c(
                                      "span",
                                      { staticClass: "c-badge gray" },
                                      [_vm._v("close")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c("span", { staticClass: "c-title" }, [
                                  _vm._v("Токен API:"),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "c-content ms-0 ms-sm-2 token_text",
                                    },
                                    [_vm._v(_vm._s(this.$store.state.token))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "c-ico d-none d-sm-inline",
                                      attrs: {
                                        "data-toggle": "tooltip",
                                        title: "Копировать в буфер",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "img-fluid",
                                        attrs: {
                                          src: "/static/images/copy.svg",
                                          alt: "edit",
                                        },
                                        on: { click: _vm.copyToken },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c("span", { staticClass: "c-title" }, [
                                  _vm._v("ID профиля:"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "c-content ms-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      this.$store.state.current_profile.uuid
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "c-ico d-none d-sm-inline",
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      title: "Копировать в буфер",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img-fluid",
                                      attrs: {
                                        src: "/static/images/copy.svg",
                                        alt: "edit",
                                      },
                                      on: { click: _vm.copyUuid },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 mt-3" },
                            [
                              _c("WebhookSettings"),
                              _vm._v(" "),
                              _c("CRMBlock", {
                                staticClass: "mt-2",
                                attrs: {
                                  profile: this.$store.state.current_profile,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-lg-6 col-12 border-left px-lg-4 px-xxl-5",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 gx-lg-2" }, [
                            _vm._m(2),
                            _vm._v(" "),
                            !this.$store.state.user.moysklad
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-block d-flex flex-wrap align-items-center",
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "16",
                                          height: "16",
                                          viewBox: "0 0 15 15",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            "clip-rule": "evenodd",
                                            d: "M0.625 2.96875C0.625 1.94372 1.54547 1.25 2.5 1.25H12.5C13.4546 1.25 14.375 1.94372 14.375 2.96875V5.15625C14.375 6.18127 13.4546 6.875 12.5 6.875H2.5C1.54547 6.875 0.625 6.18127 0.625 5.15625V2.96875ZM4.375 4.0625C4.375 4.58027 3.95527 5 3.4375 5C2.91973 5 2.5 4.58027 2.5 4.0625C2.5 3.54473 2.91973 3.125 3.4375 3.125C3.95527 3.125 4.375 3.54473 4.375 4.0625Z",
                                            fill: "#6C757D",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            "clip-rule": "evenodd",
                                            d: "M0.625 9.84375C0.625 8.81875 1.54547 8.125 2.5 8.125H12.5C13.4546 8.125 14.375 8.81875 14.375 9.84375V12.0312C14.375 13.0563 13.4546 13.75 12.5 13.75H2.5C1.54547 13.75 0.625 13.0563 0.625 12.0312V9.84375ZM4.375 10.9375C4.375 11.4553 3.95527 11.875 3.4375 11.875C2.91973 11.875 2.5 11.4553 2.5 10.9375C2.5 10.4197 2.91973 10 3.4375 10C3.95527 10 4.375 10.4197 4.375 10.9375Z",
                                            fill: "#6C757D",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "c-title",
                                        staticStyle: { "margin-left": "6px" },
                                      },
                                      [_vm._v("Прокси:")]
                                    ),
                                    _vm._v(" "),
                                    !_vm.edited_proxy &&
                                    _vm.user_proxy.length > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "c-content ms-2" },
                                          [_vm._v(_vm._s(_vm.user_proxy))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.edited_proxy &&
                                    _vm.user_proxy.length === 0
                                      ? _c(
                                          "span",
                                          { staticClass: "c-content ms-1" },
                                          [_vm._v("встроенный")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.edited_proxy
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.user_proxy,
                                              expression: "user_proxy",
                                            },
                                          ],
                                          staticClass:
                                            "form-control name c-content h- me-1 name_form ms-1 ms-sm-2 me-sm-2",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              "login:password@ip:port или ip:port",
                                          },
                                          domProps: { value: _vm.user_proxy },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.saveUserProxy.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.user_proxy =
                                                $event.target.value
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.edited_proxy
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-success",
                                            on: { click: _vm.saveUserProxy },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Сохранить\n                      "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "c-ico" }, [
                                      !_vm.edited_proxy
                                        ? _c("img", {
                                            staticClass: "img-fluid",
                                            attrs: {
                                              src: "/static/images/edit.svg",
                                              title: "Редактировать прокси",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editUserProxy()
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          "data-toggle": "tooltip",
                                          "data-placement": "right",
                                          title:
                                            "Подходят только socks5 прокси в формате `login:password@ip:port` или `ip:port`. Если поле пустое, то используются прокси сервиса",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "16",
                                              height: "16",
                                              viewBox: "0 0 16 16",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                fill: "#6c757d",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !this.user.spamer &&
                            !this.$store.state.user.moysklad
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-block d-flex flex-wrap align-items-center",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.paynotify,
                                          expression: "paynotify",
                                        },
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "remember_billing",
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.paynotify)
                                          ? _vm._i(_vm.paynotify, null) > -1
                                          : _vm.paynotify,
                                      },
                                      on: {
                                        click: _vm.paynotify_change,
                                        change: function ($event) {
                                          var $$a = _vm.paynotify,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.paynotify = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.paynotify = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.paynotify = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(3),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          "data-toggle": "tooltip",
                                          "data-placement": "right",
                                          title:
                                            "Вам на почту будут приходить уведомления об окончании оплаченого периода",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "16",
                                              height: "16",
                                              viewBox: "0 0 16 16",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                fill: "#6c757d",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !this.user.spamer &&
                            !this.$store.state.user.moysklad
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "info-block d-flex flex-wrap align-items-center",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.authnotify,
                                          expression: "authnotify",
                                        },
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "auth_notify",
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.authnotify)
                                          ? _vm._i(_vm.authnotify, null) > -1
                                          : _vm.authnotify,
                                      },
                                      on: {
                                        click: _vm.authnotify_change,
                                        change: function ($event) {
                                          var $$a = _vm.authnotify,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.authnotify = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.authnotify = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.authnotify = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(4),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          "data-toggle": "tooltip",
                                          "data-placement": "right",
                                          title:
                                            "Вам на почту будут приходить уведомления о разавторизации профиля",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "16",
                                              height: "16",
                                              viewBox: "0 0 16 16",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                                fill: "#6c757d",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "m-0 c-title",
                                    staticStyle: { color: "#009BDE" },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticStyle: { "margin-top": "-3px" },
                                        attrs: {
                                          width: "16",
                                          height: "16",
                                          viewBox: "0 0 20 20",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              "clip-path":
                                                "url(#clip0_5767_87)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M7.84758 12.6508L7.51675 17.3041C7.99008 17.3041 8.19508 17.1008 8.44091 16.8566L10.6601 14.7358L15.2584 18.1033C16.1017 18.5733 16.6959 18.3258 16.9234 17.3274L19.9417 3.1841L19.9426 3.18326C20.2101 1.9366 19.4917 1.4491 18.6701 1.75493L0.928413 8.54743C-0.28242 9.01743 -0.264087 9.69243 0.72258 9.99826L5.25841 11.4091L15.7942 4.8166C16.2901 4.48826 16.7409 4.66993 16.3701 4.99826L7.84758 12.6508Z",
                                                fill: "currentColor",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("defs", [
                                          _c(
                                            "clipPath",
                                            { attrs: { id: "clip0_5767_87" } },
                                            [
                                              _c("rect", {
                                                attrs: {
                                                  width: "20",
                                                  height: "20",
                                                  fill: "white",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(5),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticStyle: { "margin-top": "-2px" },
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "16",
                                      viewBox: "0 0 512 512",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "#6c757d",
                                        d: "M449.9 39.96l-48.5 48.53C362.5 53.19 311.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.97 5.5 34.86-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c37.96 0 73 14.18 100.2 37.8L311.1 178C295.1 194.8 306.8 223.4 330.4 224h146.9C487.7 223.7 496 215.3 496 204.9V59.04C496 34.99 466.9 22.95 449.9 39.96zM441.8 289.6c-16.94-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-37.96 0-73-14.18-100.2-37.8L200 334C216.9 317.2 205.2 288.6 181.6 288H34.66C24.32 288.3 16 296.7 16 307.1v145.9c0 24.04 29.07 36.08 46.07 19.07l48.5-48.53C149.5 458.8 200.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "c-title restore_on_open text-decoration-underline billing-history",
                                    on: { click: _vm.profile_restart },
                                  },
                                  [
                                    _c("span", { staticClass: "reboot_app" }, [
                                      _vm._v("Перезагрузить приложение"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title:
                                        "Перезагрузка приложения занимает около 60 секунд",
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "16",
                                          height: "16",
                                          viewBox: "0 0 16 16",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M15.75 8C15.75 12.2812 12.2799 15.75 8 15.75C3.72009 15.75 0.25 12.2812 0.25 8C0.25 3.72134 3.72009 0.25 8 0.25C12.2799 0.25 15.75 3.72134 15.75 8ZM8.20797 2.8125C6.50494 2.8125 5.41875 3.52991 4.56581 4.80494C4.45531 4.97012 4.49228 5.19291 4.65066 5.313L5.735 6.13519C5.89766 6.25853 6.12941 6.22919 6.25578 6.06887C6.81403 5.36081 7.19681 4.95022 8.0465 4.95022C8.68491 4.95022 9.47456 5.36109 9.47456 5.98016C9.47456 6.44816 9.08822 6.6885 8.45788 7.04191C7.72275 7.454 6.75 7.96691 6.75 9.25V9.375C6.75 9.58209 6.91791 9.75 7.125 9.75H8.875C9.08209 9.75 9.25 9.58209 9.25 9.375V9.33334C9.25 8.44391 11.8496 8.40687 11.8496 6C11.8496 4.18744 9.96941 2.8125 8.20797 2.8125ZM8 10.5625C7.20734 10.5625 6.5625 11.2073 6.5625 12C6.5625 12.7926 7.20734 13.4375 8 13.4375C8.79266 13.4375 9.4375 12.7926 9.4375 12C9.4375 11.2073 8.79266 10.5625 8 10.5625Z",
                                            fill: "#6c757d",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticStyle: { "margin-top": "-2px" },
                                    attrs: {
                                      width: "18",
                                      viewBox: "0 0 24 24",
                                      fill: "currentColor",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "fill-rule": "evenodd",
                                        "clip-rule": "evenodd",
                                        d: "M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z",
                                        fill: "#6c757d",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "c-title text-decoration-underline billing-history delete_link restore_on_open",
                                    on: { click: _vm.deleteInstance },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "6px" } },
                                      [_vm._v("Удалить профиль")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 gx-lg-2 mt-3" }, [
                            _vm._m(6),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c("span", { staticClass: "m-0 c-title" }, [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "18",
                                        height: "18",
                                        viewBox: "0 0 20 20",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.515 5 14.934V20L10.34 15.995C15.697 15.853 20 12.32 20 8C20 3.589 15.514 0 10 0ZM9 12.414L5.293 8.707L6.707 7.293L9 9.586L13.793 4.793L15.207 6.207L9 12.414Z",
                                          fill: "currentColor",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "c-title" }, [
                                    _vm._v(
                                      "Отправлено сегодня:\n                          "
                                    ),
                                    _c("span", { staticClass: "c-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          this.$store.state.today_tasks_count
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c("span", { staticClass: "m-0 c-title" }, [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "18",
                                        height: "18",
                                        viewBox: "0 0 20 20",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.515 5 14.934V20L10.34 15.995C15.697 15.853 20 12.32 20 8C20 3.589 15.514 0 10 0ZM9 12.414L5.293 8.707L6.707 7.293L9 9.586L13.793 4.793L15.207 6.207L9 12.414Z",
                                          fill: "currentColor",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "c-title" }, [
                                    _vm._v(
                                      "Отправлено всего:\n                          "
                                    ),
                                    _c("span", { staticClass: "c-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          this.$store.state.current_profile
                                            .message_count
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c("span", { staticClass: "m-0 c-title" }, [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "18",
                                        height: "18",
                                        viewBox: "0 0 20 20",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.516 5 14.934V20L10.34 15.995C15.697 15.854 20 12.32 20 8C20 3.589 15.514 0 10 0ZM11 12H9V10H11V12ZM11 8H9V3H11V8Z",
                                          fill: "currentColor",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "c-title" }, [
                                    _vm._v(
                                      "Сообщений в очереди:\n                          "
                                    ),
                                    _c("span", { staticClass: "c-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          this.$store.state.pending_tasks_count
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "c-title delete_link restore_on_open",
                                      on: { click: _vm.purgeQueue },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mx-0",
                                          attrs: {
                                            "data-toggle": "tooltip",
                                            "data-placement": "right",
                                            title:
                                              "Очистить сообщения из очереди",
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticStyle: {
                                                "margin-top": "-2px",
                                              },
                                              attrs: {
                                                width: "18",
                                                viewBox: "0 0 24 24",
                                                fill: "currentColor",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  "fill-rule": "evenodd",
                                                  "clip-rule": "evenodd",
                                                  d: "M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z",
                                                  fill: "currentColor",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "info-block d-flex flex-wrap align-items-center",
                              },
                              [
                                _c("span", { staticClass: "m-0 c-title" }, [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "18",
                                        height: "18",
                                        viewBox: "0 0 20 20",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M10 0C4.486 0 0 3.589 0 8C0 10.907 1.897 13.515 5 14.934V20L10.34 15.995C15.697 15.853 20 12.32 20 8C20 3.589 15.514 0 10 0ZM12 11H5V9H12V11ZM15 7H5V5H15V7Z",
                                          fill: "currentColor",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "c-title" },
                                    [
                                      _c(
                                        "inertia-link",
                                        {
                                          staticClass:
                                            "c-title text-decoration-underline billing-history",
                                          attrs: {
                                            href: _vm.route(
                                              "dashboard.messages_history",
                                              this.$store.state.current_profile
                                                .uuid
                                            ),
                                          },
                                        },
                                        [_vm._v("История сообщений")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row g-0 g-sm-2 g-md-4" }, [
            _c(
              "div",
              {
                staticClass: "col-lg-6 col-md-6 col-sm-12 col-12 block_qr_code",
              },
              [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _vm._m(7),
                    _vm._v(" "),
                    this.$store.state.current_profile.authorized
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-success d-flex align-items-center mt-3",
                            attrs: { role: "alert" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "25",
                                  height: "24",
                                  viewBox: "0 0 25 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M23.0263 11.2632C23.0263 16.7872 18.5493 21.2632 13.0263 21.2632C7.50331 21.2632 3.02631 16.7872 3.02631 11.2632C3.02631 5.74115 7.50331 1.26315 13.0263 1.26315C18.5493 1.26315 23.0263 5.74115 23.0263 11.2632Z",
                                    fill: "#12703A",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M11.261 17C10.9328 17 10.6045 16.8692 10.3538 16.6059L6.87593 12.9532C6.37469 12.4268 6.37469 11.574 6.87593 11.0491C7.37717 10.5227 8.18765 10.5211 8.6889 11.0476L11.261 13.749L17.3111 7.39483C17.8123 6.86839 18.6228 6.86839 19.1241 7.39483C19.6253 7.92127 19.6253 8.77403 19.1241 9.30047L12.1683 16.6059C11.9176 16.8692 11.5893 17 11.261 17Z",
                                    fill: "white",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ms-2 alert_text" }, [
                              _vm._v("Вы авторизованы"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !this.$store.state.current_profile.authorized
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-danger d-flex mt-3 align-items-center",
                            attrs: { role: "alert" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M4.72251 21.1672C4.70951 21.1672 4.69751 21.1672 4.68351 21.1662C4.36851 21.1502 4.05951 21.0822 3.76551 20.9632C2.31851 20.3752 1.62051 18.7222 2.20751 17.2762L9.52851 4.45025C9.78051 3.99425 10.1625 3.61225 10.6285 3.35425C11.9935 2.59825 13.7195 3.09525 14.4745 4.45925L21.7475 17.1872C21.9095 17.5682 21.9785 17.8782 21.9955 18.1942C22.0345 18.9502 21.7765 19.6752 21.2705 20.2362C20.7645 20.7972 20.0695 21.1282 19.3145 21.1662L4.79451 21.1672H4.72251Z",
                                    fill: "#862317",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M11.1245 10.0208C11.1245 9.53875 11.5175 9.14575 11.9995 9.14575C12.4815 9.14575 12.8745 9.53875 12.8745 10.0208V12.8488C12.8745 13.3318 12.4815 13.7238 11.9995 13.7238C11.5175 13.7238 11.1245 13.3318 11.1245 12.8488V10.0208ZM11.1245 16.2699C11.1245 15.7849 11.5175 15.3899 11.9995 15.3899C12.4815 15.3899 12.8745 15.7799 12.8745 16.2589C12.8745 16.7519 12.4815 17.1449 11.9995 17.1449C11.5175 17.1449 11.1245 16.7519 11.1245 16.2699Z",
                                    fill: "white",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ms-2" }, [
                              _vm._v("Вы не авторизованы"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3 mb-sm-0" }, [
                      _c("div", {
                        staticClass: "col-12 d-flex justify-content-center",
                      }),
                      _vm._v(" "),
                      this.$store.state.current_profile.authorized &&
                      !_vm.is_task_exist
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-md-12 col-lg-12 text-center",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn resp-button btn-outline-secondary text-decoration-none restore_on_open mt-2 mb-1",
                                  attrs: { type: "button" },
                                  on: { click: _vm.profile_logout },
                                },
                                [
                                  _c("span", { staticClass: "me-2" }, [
                                    _c(
                                      "svg",
                                      {
                                        staticStyle: { "margin-top": "-2px" },
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "18",
                                          viewBox: "0 0 512 512",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                  Выйти из telegram\n                "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    this.$store.state.current_profile.authorized
                      ? _c(
                          "div",
                          { staticClass: "col-12 col-md-12 col-lg-12 c-title" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mt-3 text-left",
                                staticStyle: { color: "#6c757d" },
                              },
                              [
                                _vm._v(
                                  "\n                Последний раз вы были авторизованы\n                " +
                                    _vm._s(
                                      _vm._f("short")(
                                        this.$store.state.current_profile
                                          .authorized_at
                                      )
                                    ) +
                                    "\n                с номера\n                "
                                ),
                                _c("span", { staticClass: "c-content" }, [
                                  _vm._v(
                                    _vm._s(
                                      this.$store.state.current_profile.phone
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  ". Если вы хотите сбросить авторизацию этого профиля в\n                Telegram нажмите «Выйти из Telegram».\n              "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column col-12 text-center" },
                      [
                        !this.$store.state.current_profile.authorized
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-12 col-lg-12 text-center",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn resp-button btn-billing text-decoration-none mt-2 mb-2",
                                    attrs: {
                                      id: "qr_request_button",
                                      type: "button",
                                    },
                                    on: { click: _vm.authorize },
                                  },
                                  [
                                    _c("span", { staticClass: "me-2" }, [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "20",
                                            height: "20",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 448 512",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              fill: "#fff",
                                              d: "M144 32C170.5 32 192 53.49 192 80V176C192 202.5 170.5 224 144 224H48C21.49 224 0 202.5 0 176V80C0 53.49 21.49 32 48 32H144zM128 96H64V160H128V96zM144 288C170.5 288 192 309.5 192 336V432C192 458.5 170.5 480 144 480H48C21.49 480 0 458.5 0 432V336C0 309.5 21.49 288 48 288H144zM128 352H64V416H128V352zM256 80C256 53.49 277.5 32 304 32H400C426.5 32 448 53.49 448 80V176C448 202.5 426.5 224 400 224H304C277.5 224 256 202.5 256 176V80zM320 160H384V96H320V160zM352 448H384V480H352V448zM448 480H416V448H448V480zM416 288H448V416H352V384H320V480H256V288H352V320H416V288z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Авторизовать")]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !this.$store.state.current_profile.authorized
                          ? _c(
                              "div",
                              { staticClass: "col-12 mt-2 text-left" },
                              [
                                _vm._m(8),
                                _vm._v(" "),
                                _vm._m(9),
                                _vm._v(" "),
                                _vm._m(10),
                                _vm._v(" "),
                                _vm._m(11),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-3 text-left" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "c-title text-decoration-underline billing-history",
                              on: { click: _vm.showAuthHistory },
                            },
                            [_vm._v("История авторизаций")]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-lg-6 col-md-6 col-sm-12 col-12 block_billing",
              },
              [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _vm._m(12),
                    _vm._v(" "),
                    !_vm.user.with_payments
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-success vip-partner d-flex align-items-center mt-3",
                            attrs: { role: "alert" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "20",
                                  height: "17",
                                  viewBox: "0 0 20 17",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M1.97162 2.03967L5.77662 5.38767L9.16662 1.21367C9.26154 1.09698 9.38157 1.00321 9.51776 0.939341C9.65395 0.875473 9.8028 0.843152 9.95322 0.844786C10.1036 0.846419 10.2517 0.881966 10.3865 0.948778C10.5213 1.01559 10.6392 1.11195 10.7316 1.23067L13.9666 5.38667L17.8946 1.99067C18.0489 1.85758 18.2401 1.77478 18.4427 1.75335C18.6453 1.73192 18.8496 1.77289 19.0283 1.87077C19.207 1.96865 19.3515 2.11877 19.4425 2.30104C19.5335 2.4833 19.5667 2.68903 19.5376 2.89067L18.0376 13.2377H1.84462L0.321618 2.93767C0.291365 2.73491 0.324163 2.52774 0.415568 2.34424C0.506974 2.16074 0.652571 2.00976 0.832638 1.91176C1.01271 1.81376 1.21854 1.77347 1.42227 1.79635C1.626 1.81923 1.81777 1.90417 1.97162 2.03967ZM1.92262 14.2377H17.9226V15.2377C17.9226 15.5029 17.8173 15.7572 17.6297 15.9448C17.4422 16.1323 17.1878 16.2377 16.9226 16.2377H2.92262C2.6574 16.2377 2.40305 16.1323 2.21551 15.9448C2.02797 15.7572 1.92262 15.5029 1.92262 15.2377V14.2377Z",
                                    fill: "#FFA300",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ms-2" }, [
                              _vm._v("VIP Партнер"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.show_not_paid && _vm.user.with_payments
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-success d-flex align-items-center mt-3",
                            attrs: { role: "alert" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "25",
                                  height: "24",
                                  viewBox: "0 0 25 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M23.0263 11.2632C23.0263 16.7872 18.5493 21.2632 13.0263 21.2632C7.50331 21.2632 3.02631 16.7872 3.02631 11.2632C3.02631 5.74115 7.50331 1.26315 13.0263 1.26315C18.5493 1.26315 23.0263 5.74115 23.0263 11.2632Z",
                                    fill: "#12703A",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M11.261 17C10.9328 17 10.6045 16.8692 10.3538 16.6059L6.87593 12.9532C6.37469 12.4268 6.37469 11.574 6.87593 11.0491C7.37717 10.5227 8.18765 10.5211 8.6889 11.0476L11.261 13.749L17.3111 7.39483C17.8123 6.86839 18.6228 6.86839 19.1241 7.39483C19.6253 7.92127 19.6253 8.77403 19.1241 9.30047L12.1683 16.6059C11.9176 16.8692 11.5893 17 11.261 17Z",
                                    fill: "white",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            this.$store.state.current_profile.crm_id != "mspay"
                              ? _c("div", { staticClass: "ms-2" }, [
                                  _vm._v("Оплачено"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            this.$store.state.current_profile.crm_id == "mspay"
                              ? _c("div", { staticClass: "ms-2" }, [
                                  _vm._v("Оплачено через МойСклад"),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.show_not_paid && _vm.user.with_payments
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-danger d-flex align-items-center mt-3",
                            attrs: { role: "alert" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "24",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M4.72251 21.1672C4.70951 21.1672 4.69751 21.1672 4.68351 21.1662C4.36851 21.1502 4.05951 21.0822 3.76551 20.9632C2.31851 20.3752 1.62051 18.7222 2.20751 17.2762L9.52851 4.45025C9.78051 3.99425 10.1625 3.61225 10.6285 3.35425C11.9935 2.59825 13.7195 3.09525 14.4745 4.45925L21.7475 17.1872C21.9095 17.5682 21.9785 17.8782 21.9955 18.1942C22.0345 18.9502 21.7765 19.6752 21.2705 20.2362C20.7645 20.7972 20.0695 21.1282 19.3145 21.1662L4.79451 21.1672H4.72251Z",
                                    fill: "#862317",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    "clip-rule": "evenodd",
                                    d: "M11.1245 10.0208C11.1245 9.53875 11.5175 9.14575 11.9995 9.14575C12.4815 9.14575 12.8745 9.53875 12.8745 10.0208V12.8488C12.8745 13.3318 12.4815 13.7238 11.9995 13.7238C11.5175 13.7238 11.1245 13.3318 11.1245 12.8488V10.0208ZM11.1245 16.2699C11.1245 15.7849 11.5175 15.3899 11.9995 15.3899C12.4815 15.3899 12.8745 15.7799 12.8745 16.2589C12.8745 16.7519 12.4815 17.1449 11.9995 17.1449C11.5175 17.1449 11.1245 16.7519 11.1245 16.2699Z",
                                    fill: "white",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            this.$store.state.current_profile.crm_id != "mspay"
                              ? _c("div", { staticClass: "ms-2" }, [
                                  _vm._v("Не оплачено"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            this.$store.state.current_profile.crm_id == "mspay"
                              ? _c("div", { staticClass: "ms-2" }, [
                                  _vm._v("Не оплачено. Привязан к МойСклад"),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    this.$store.state.current_profile.is_subscribe
                      ? _c(
                          "div",
                          {
                            staticClass: "alert description",
                            attrs: { role: "alert" },
                          },
                          [
                            _vm._v(
                              "\n              Оформлена подписка.\n              "
                            ),
                            _c("span", { staticClass: "text-gray" }, [
                              _vm._v("Следующее списание:"),
                            ]),
                            _vm._v(" "),
                            typeof _vm.last_payment.tariff !== "undefined"
                              ? _c("span", { staticClass: "text-gray" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("short")(
                                          _vm.last_payment.expired_at
                                        )
                                      ) +
                                      " —\n                " +
                                      _vm._s(_vm.last_payment.tariff["price"]) +
                                      "₽\n              "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-2" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-decoration-underline billing-history",
                                  on: { click: _vm.cancel_subscribe },
                                },
                                [_vm._v("Отменить подписку")]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    typeof _vm.last_payment !== "undefined"
                      ? _c("div", { staticClass: "row" }, [
                          _vm.hours_left == 0
                            ? _c("div", { staticClass: "col-6" }, [
                                _c(
                                  "span",
                                  { staticClass: "text-uppercase text-up" },
                                  [_vm._v("Осталось дней")]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "text-green-big" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.days_left_sklad) + " д."
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hours_left != 0
                            ? _c("div", { staticClass: "col-6" }, [
                                _c(
                                  "span",
                                  { staticClass: "text-uppercase text-up" },
                                  [_vm._v("Осталось часов")]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "text-green-big" },
                                    [_vm._v(_vm._s(_vm.hours_left) + " ч.")]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "span",
                              { staticClass: "text-uppercase text-up" },
                              [_vm._v("оплачено до")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm.last_payment
                                ? _c("span", { staticClass: "billing-date" }, [
                                    _vm._v(_vm._s(_vm.expired_at_sklad)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-17" }, [
                      this.$store.state.current_profile.crm_id != "mspay"
                        ? _c("span", { staticClass: "c-title" }, [
                            _vm._v(
                              "Своевременно оплачивайте профили во избежание отключения.\n                Рекомендуем вам включить напоминания, либо оформить\n                подписку."
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      this.$store.state.current_profile.crm_id == "mspay"
                        ? _c("span", { staticClass: "c-title" }, [
                            _vm._v(
                              "Этот профиль привязан к оплате через МойСклад. Если не оплатить вовремя - перестанет работать вся интеграция."
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    this.$store.state.current_profile.crm_id != "mspay"
                      ? _c(
                          "div",
                          { staticClass: "mt-3 row align-items-center" },
                          [
                            _c("div", { staticClass: "col-md-6 text-center" }, [
                              this.$store.state.user.use_test_tg
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-billing",
                                      attrs: { type: "button" },
                                      on: { click: _vm.showPayModal },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Оплатить\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !this.$store.state.user.use_test_tg
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-billing",
                                      attrs: { type: "button" },
                                      on: { click: _vm.showPayModal },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Бесплатный тест\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-6 text-center billing__history",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "c-title text-decoration-underline billing-history",
                                    on: { click: _vm.showPayHistoryModal },
                                  },
                                  [_vm._v("История\n                  оплаты")]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "hello-modal",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "exampleModalLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-dialog", attrs: { role: "document" } },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-content p-4 p-lg-33 rounded" },
                      [
                        _vm._m(13),
                        _vm._v(" "),
                        _vm._m(14),
                        _vm._v(" "),
                        _vm._m(15),
                        _vm._v(" "),
                        _vm._m(16),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "info-block mt-4 d-flex flex-wrap align-items-center",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isNeverShowHello,
                                  expression: "isNeverShowHello",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox", id: "never" },
                              domProps: {
                                checked: Array.isArray(_vm.isNeverShowHello)
                                  ? _vm._i(_vm.isNeverShowHello, null) > -1
                                  : _vm.isNeverShowHello,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.isNeverShowHello,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isNeverShowHello = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isNeverShowHello = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isNeverShowHello = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm._m(17),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center mt-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-billing",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "aria-label": "Close",
                              },
                              on: { click: _vm.neverShowHello },
                            },
                            [_vm._v("\n                ОК\n              ")]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "qr-modal",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "exampleModalLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-dialog", attrs: { role: "document" } },
                  [
                    _c("div", { staticClass: "modal-content p-2 p-lg-33" }, [
                      _c("img", {
                        staticClass: "img-fluid w-100",
                        attrs: { src: _vm.base64_qr, alt: "qr code" },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "pay-modal",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "exampleModalLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-dialog", attrs: { role: "document" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-content p-lg-33",
                        staticStyle: { padding: "24px" },
                      },
                      [
                        _c("div", { staticClass: "modal-header" }, [
                          _c(
                            "h5",
                            {
                              staticClass: "modal-title",
                              staticStyle: { "line-height": "100%" },
                              attrs: { id: "exampleModalLabel" },
                            },
                            [
                              _vm._v("\n                Оплата "),
                              _c(
                                "span",
                                { staticStyle: { color: "#23BB86" } },
                                [_vm._v(_vm._s(this.profileName))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "swal2-close",
                              staticStyle: {
                                display: "flex",
                                "margin-top": "8px",
                                "margin-right": "8px",
                              },
                              attrs: {
                                type: "button",
                                "aria-label": "Close this dialog",
                              },
                              on: { click: _vm.showPayModal },
                            },
                            [_vm._v("×")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", {}, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "billing-alert-text billing-alert mt-23",
                            },
                            [
                              _vm._v(
                                "\n                Действует система скидок - чем больший период оплачиваете, тем\n                больше экономите!\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "billing-content-table mt-23" },
                            [
                              _c(
                                "div",
                                { staticClass: "billing-price" },
                                [
                                  _vm.is_subscribe === false
                                    ? _c("p", { staticClass: "tariff_desc" }, [
                                        _vm._v(
                                          "\n                    Единоразовый платеж за выбранный период\n                  "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.is_subscribe === true
                                    ? _c("p", { staticClass: "tariff_desc" }, [
                                        _vm._v(
                                          "\n                    Оформление автоплатежей на выбранный период. Мы будем\n                    списывать деньги автоматически для продления выбранного\n                    тарифа. Вы сможете отменить подписку в любой момент\n                  "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.tariffs, function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass:
                                          "d-flex align-items-center pl-23 item-select p-2 p-lg-3 tariff_text",
                                        class: {
                                          "item-selected": item.checked,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.select_price(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "modal-price-radio",
                                          attrs: {
                                            type: "radio",
                                            name: "radios",
                                            id: "1m",
                                          },
                                          domProps: { checked: item.checked },
                                          on: {
                                            click: function ($event) {
                                              return _vm.select_price(item)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        item.id == 5
                                          ? _c(
                                              "span",
                                              { staticClass: "content-text" },
                                              [
                                                _vm._v(
                                                  "\n                      Активировать промо период"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.id != 5
                                          ? _c(
                                              "span",
                                              { staticClass: "content-text" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("month")(item.month)
                                                  ) + " — "
                                                ),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(item.price) + "₽"
                                                  ),
                                                ]),
                                                item.discount > 0
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "c-content discount_text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Скидка " +
                                                            _vm._s(
                                                              item.discount
                                                            ) +
                                                            "₽"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "content-text text-center mt-2 mt-lg-23 mb-2 mb-lg-0",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Платеж через сервис Robokassa - принимаются банковские карты РФ, зарубежные Visa и Mastercard\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-center mx-auto" },
                          [
                            !this.is_subscribe
                              ? _c(
                                  "p",
                                  { staticClass: "text-small text-gray mt-3" },
                                  [
                                    _vm._v(
                                      "\n                Для дополнительных скидок\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-small c-content pointer",
                                        on: { click: _vm.promoCode },
                                      },
                                      [_vm._v("введите промокод")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("transition", { attrs: { name: "fade" } }, [
                              _vm.isPromo
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.promocodeValue,
                                        expression: "promocodeValue",
                                      },
                                    ],
                                    staticClass:
                                      "form-control mx-auto c-content mt-2",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Промокод",
                                    },
                                    domProps: { value: _vm.promocodeValue },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.promoCode.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.promocodeValue = $event.target.value
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            !_vm.isPromo && !_vm.sendingPayment
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-billing text-uppercase mt-3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.send_payment_request()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Оплатить " +
                                        _vm._s(_vm.price_value)
                                    ),
                                    _vm.price_value
                                      ? _c("span", [_vm._v("₽")])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isPromo && _vm.sendingPayment
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-billing text-uppercase mt-3",
                                    attrs: { type: "button", disabled: "" },
                                  },
                                  [_vm._m(18)]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPromo
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn-billing mt-3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.promoCode()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Применить промокод\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "billinghistory-modal",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "exampleModalLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-xl",
                    attrs: { role: "document" },
                  },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-head d-flex align-items-start justify-content-between",
                        },
                        [
                          _c(
                            "h5",
                            {
                              staticClass: "modal-title",
                              attrs: { id: "billing-history-modal-label" },
                            },
                            [
                              _vm._v("\n                История оплаты "),
                              _c(
                                "span",
                                { staticStyle: { color: "#23BB86" } },
                                [_vm._v(_vm._s(this.profileName))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "swal2-close",
                              attrs: { type: "button" },
                              on: { click: _vm.showPayHistoryModal },
                            },
                            [_vm._v("×")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {}, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table table-striped" }, [
                            _c(
                              "tbody",
                              [
                                _vm._m(19),
                                _vm._v(" "),
                                _vm._l(_vm.payments, function (payment, index) {
                                  return _c(
                                    "tr",
                                    { key: index, staticClass: "text-center" },
                                    [
                                      _c("td", [
                                        _vm._v(_vm._s(payment.payment_at)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(payment.tariff.price)),
                                      ]),
                                      _vm._v(" "),
                                      payment.is_auto_payment == true
                                        ? _c("td", [
                                            _vm._v(
                                              "\n                        Автоплатеж\n                      "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      payment.is_auto_payment == false
                                        ? _c("td", [
                                            _vm._v(
                                              "\n                        Разовый платеж\n                      "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      payment.tariff.price == 0
                                        ? _c("td", [_vm._v("Промопериод")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      payment.tariff.price > 0
                                        ? _c("td", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(payment.tariff.month) +
                                                " месяца -\n                        " +
                                                _vm._s(payment.tariff.price) +
                                                " руб. (скидка\n                        " +
                                                _vm._s(
                                                  payment.tariff.discount
                                                ) +
                                                " руб.)\n                      "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      payment.status
                                        ? _c("td", [_vm._v("Оплачено")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !payment.status
                                        ? _c("td", [_vm._v("Не оплачено")])
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "billing-history-modal",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "exampleModalLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-lg",
                    attrs: { role: "document" },
                  },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-head d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("h5", { staticClass: "modal-title" }, [
                            _vm._v("Настройки аккаунта"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "aria-label": "Close",
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "16",
                                    height: "15",
                                    viewBox: "0 0 16 15",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z",
                                      fill: "#6C757D",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {}),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "auth_history_modal",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "exampleModalLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-lg",
                    attrs: { role: "document" },
                  },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-head d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("h5", { staticClass: "modal-title" }, [
                            _vm._v("История авторизаций "),
                            _c("span", { staticStyle: { color: "#23BB86" } }, [
                              _vm._v(_vm._s(this.profileName)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "swal2-close",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "aria-label": "Close",
                              },
                              on: { click: _vm.showAuthHistory },
                            },
                            [_vm._v("×")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {}, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table table-striped" }, [
                            _c(
                              "tbody",
                              [
                                _vm._m(20),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.authorizations,
                                  function (auth, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        staticClass: "text-center",
                                        class: {
                                          "table-success":
                                            auth.type === "login",
                                          "table-danger": auth.type !== "login",
                                        },
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("wtz")(auth.created_at)
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [_vm._v(_vm._s(auth.phone))]),
                                        _vm._v(" "),
                                        auth.type === "login"
                                          ? _c("td", [_vm._v("Login")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        auth.type === "logout"
                                          ? _c("td", [_vm._v("Logout")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("td", [_vm._v(_vm._s(auth.reason))]),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      !this.$store.state.user.moysklad ? _c("Footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-fluid iq-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", [
            _c("h1", { staticClass: "c-content d-md-block" }, [
              _vm._v("Telegram профиль"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "c-title" }, [
      _vm._v("Статус"),
      _c("span", { staticClass: "d-none d-sm-inline" }, [
        _vm._v(" приложения"),
      ]),
      _vm._v(":"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "info-block d-flex flex-wrap align-items-center",
        staticStyle: { padding: "8px 0" },
      },
      [
        _c("span", { staticClass: "c-title" }, [
          _c("i", [_vm._v("Настройки профиля:")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "m-0", attrs: { for: "remember_billing" } },
      [
        _c("span", { staticClass: "c-title" }, [
          _vm._v("Напоминания об оплате"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "m-0", attrs: { for: "auth_notify" } }, [
      _c("span", { staticClass: "c-title" }, [
        _vm._v("Уведомления о разавторизации"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "c-title", staticStyle: { "margin-left": "2px" } },
      [
        _c(
          "a",
          {
            staticClass: "c-title text-decoration-underline billing-history",
            staticStyle: { color: "#009BDE" },
            attrs: { href: "https://t.me/WappiProBot", target: "_blank" },
          },
          [_vm._v("Уведомления в Telegram")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "info-block d-flex flex-wrap align-items-center" },
      [
        _c("span", { staticClass: "c-title" }, [
          _c("i", [_vm._v("Статистика сообщений:")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-wrap align-items-center justify-content-between",
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap align-items-center mb-3 mb-sm-0" },
          [_c("h4", { staticClass: "h4" }, [_vm._v("Авторизация")])]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title mb-sm-2 mb-2" }, [
      _vm._v("Подключите ваш "),
      _c("span", { staticStyle: { color: "#009BDE" } }, [_vm._v("Telegram")]),
      _vm._v(":"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title" }, [
      _vm._v("1. Укажите "),
      _c("span", { staticClass: "c-content" }, [_vm._v("номер телефона")]),
      _vm._v(", на который зарегистрирован телеграм."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title" }, [
      _vm._v("2. Введите "),
      _c("span", { staticClass: "c-content" }, [_vm._v("код авторизации")]),
      _vm._v(", который пришел в ваш телеграм."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-title" }, [
      _vm._v("3. При необходимости введите "),
      _c("span", { staticClass: "c-content" }, [_vm._v("пароль")]),
      _vm._v(" двухфакторной авторизации."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-wrap align-items-center justify-content-between",
      },
      [_c("h4", { staticClass: "me-2 h4" }, [_vm._v("Оплата")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-auto" }, [
      _c("h5", { staticClass: "modal-title text-black" }, [
        _vm._v("\n                Как начать пользоваться "),
        _c("span", { staticStyle: { color: "#009BDE" } }, [
          _c("b", [_vm._v("Telegram")]),
        ]),
        _vm._v(" "),
        _c("strong", [_vm._v("Wappi")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "rounded alert-info p-4 mt-10" }, [
        _c("div", { staticClass: "text-black text-center" }, [
          _c("h5", [_vm._v("1. Оплатите")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-gray mt-2" }, [
          _vm._v(
            "\n                  Чтобы воспользоваться бесплатным промопериодом нажмите\n                  "
          ),
          _c("button", { staticClass: "btn btn-success btn-min" }, [
            _vm._v("БЕСПЛАТНЫЙ ТЕСТ"),
          ]),
          _vm._v(",\n                  далее выберите тариф "),
          _c("u", [_vm._v("«Активировать промо период»")]),
          _vm._v(".\n                "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("div", { staticClass: "rounded alert-primary p-4 mt-23" }, [
        _c("div", { staticClass: "text-black text-center" }, [
          _c("h5", [_vm._v("2. Подключите Telegram")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-gray mt-2" }, [
          _vm._v(
            "\n                  Чтобы подключить ваш\n                  "
          ),
          _c("span", { staticStyle: { color: "#009BDE" } }, [
            _vm._v("Telegram"),
          ]),
          _vm._v(
            " к нашему сервису\n                  - нажмите Авторизовать и авторизуйтесь в приложении\n                  "
          ),
          _c("span", { staticStyle: { color: "#009BDE" } }, [
            _vm._v("Telegram"),
          ]),
          _vm._v(" на телефоне.\n                "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("div", { staticClass: "rounded alert-success p-4 mt-23" }, [
        _c("div", { staticClass: "text-black text-center" }, [
          _c("h5", [_vm._v("3. Пользуйтесь Telegram API")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-gray mt-2" }, [
          _vm._v(
            "\n                  Ознакомиться с подробной документацией и протестировать\n                  запросы нашего Telegram API можно в разделе\n                  "
          ),
          _c("a", { attrs: { href: "/telegram-api-documentation" } }, [
            _c("u", [_vm._v("Документация")]),
          ]),
          _vm._v(".\n                "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "m-0", attrs: { for: "never" } }, [
      _c("span", { staticClass: "c-title" }, [_vm._v("Больше не показывать")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "text-center text-uppercase" }, [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Дата")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Сумма")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Тип оплаты")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Тариф")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Статус оплаты")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "text-center text-uppercase" }, [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Дата")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Телефон")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Авторизация")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Причина")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }