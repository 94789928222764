var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Sidebar", {
        attrs: { all_profiles: "all_profiles" },
        on: { side_toggle: _vm.sidebar_toggle },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "main-content" },
        [
          _c("Topbar", { on: { side_toggle: _vm.sidebar_toggle } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iq-navbar-header" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "iq-header-img" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container-fluid content-inner py-0 my-mt-5" },
                [
                  _c("div", { staticClass: "row g-0 g-sm-2 g-md-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12",
                      },
                      [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "mass_title responder_head_margin",
                                },
                                [_vm._v(_vm._s(_vm.cascade.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "responder_head_margin" },
                                [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.cascade.name,
                                          expression: "cascade.name",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", name: "name" },
                                      domProps: { value: _vm.cascade.name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.cascade,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(2),
                              _vm._v(" "),
                              _vm._l(_vm.cascade.order, function (order, i) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "responder_head_margin",
                                    staticStyle: {
                                      background: "#F3FFF6",
                                      padding: "24px",
                                      "margin-bottom": "26px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between mb-2",
                                        staticStyle: {
                                          color: "#6C757D",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(i + 1) +
                                              ". " +
                                              _vm._s(
                                                _vm.getTitleByPlatform(
                                                  order.platform
                                                )
                                              )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            attrs: {
                                              width: "15",
                                              height: "15",
                                              viewBox: "0 0 15 15",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.order_delete(i)
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                "clip-rule": "evenodd",
                                                d: "M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L7.5 6.32149L13.5774 0.244078C13.9028 -0.0813592 14.4305 -0.0813592 14.7559 0.244078C15.0814 0.569515 15.0814 1.09715 14.7559 1.42259L8.67851 7.5L14.7559 13.5774C15.0814 13.9029 15.0814 14.4305 14.7559 14.7559C14.4305 15.0814 13.9028 15.0814 13.5774 14.7559L7.5 8.67851L1.42259 14.7559C1.09715 15.0814 0.569515 15.0814 0.244078 14.7559C-0.0813592 14.4305 -0.0813592 13.9029 0.244078 13.5774L6.32149 7.5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z",
                                                fill: "#232D42",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._m(3, true),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: order.profile_uuid,
                                              expression: "order.profile_uuid",
                                            },
                                          ],
                                          staticClass: "form-select",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  order,
                                                  "profile_uuid",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                return _vm.select_profile(
                                                  $event,
                                                  i
                                                )
                                              },
                                            ],
                                          },
                                        },
                                        _vm._l(
                                          _vm.profiles,
                                          function (profile) {
                                            return _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: profile.uuid,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(profile.name) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.get_platform(
                                                        profile.platform
                                                      )
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "responder_add mb-5",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.order_add.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "mr16",
                                      attrs: {
                                        width: "20",
                                        height: "24",
                                        viewBox: "0 0 20 24",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        { attrs: { id: "plus-solid 1" } },
                                        [
                                          _c("path", {
                                            attrs: {
                                              id: "Vector",
                                              d: "M18.5714 9.75H12.1429V3C12.1429 2.17172 11.5031 1.5 10.7143 1.5H9.28571C8.49688 1.5 7.85714 2.17172 7.85714 3V9.75H1.42857C0.639732 9.75 0 10.4217 0 11.25V12.75C0 13.5783 0.639732 14.25 1.42857 14.25H7.85714V21C7.85714 21.8283 8.49688 22.5 9.28571 22.5H10.7143C11.5031 22.5 12.1429 21.8283 12.1429 21V14.25H18.5714C19.3603 14.25 20 13.5783 20 12.75V11.25C20 10.4217 19.3603 9.75 18.5714 9.75Z",
                                              fill: "currentColor",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Добавить канал")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "responder_head_margin responder_submit_buttons",
                                },
                                [
                                  !_vm.sending
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "resp_button_green mr24",
                                          on: {
                                            click: function ($event) {
                                              return _vm.save_cascade()
                                            },
                                          },
                                        },
                                        [_vm._v("Сохранить")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.sending
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "resp_button_green mr24",
                                          attrs: { disabled: "" },
                                        },
                                        [_vm._m(4)]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "inertia-link",
                                    {
                                      staticClass: "resp_button_grey2",
                                      attrs: {
                                        href: _vm.route("cascade.index"),
                                      },
                                    },
                                    [_vm._v("Отменить")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(5),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("Footer"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-fluid iq-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-wrap",
            },
            [
              _c("div", [
                _c("h1", { staticClass: "c-content" }, [_vm._v("Каскады")]),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-label d-flex justify-content-between align-items-center",
      },
      [_c("b", [_vm._v("Название")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "wtpl_subtitle_2 d-flex align-items-center justify-content-center",
      },
      [_c("div", [_vm._v("Добавьте каналы в нужном порядке")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c(
          "span",
          {
            staticClass: "input_desc",
            staticStyle: { "margin-left": "1px", "margin-bottom": "2px" },
          },
          [_vm._v("Выберите профиль")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12" },
      [
        _c("div", { staticClass: "card card_desc" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h2", { staticClass: "mass_title mb-3" }, [
              _vm._v("Каскады - как это работает?"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("p", [
                _vm._v(
                  "С помощью каскадов вы можете организовать рассылку сообщений в разные каналы - Whatsapp, Telegram и СМС.  "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "При каскадной рассылке вы можете задать последовательность каналов. Если очередной канал не позволяет доставить сообщение абоненту, то система отправляет его на следующий."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Использование каскадов в рассылке позволяет повысить вероятность, что сообщение дойдет до адресата. Если у клиента нет одного канала, то сообщение уйдет по другому. Идеально подходит для автоматических уведомлений и сервисных сообщений."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Вы можете использовать каскады для отправки сообщений по API, в нашем сервисе Рассылки сообщений или в любой нашей интеграции, выбрав нужный каскад как обычный профиль."
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }