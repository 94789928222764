<template>
  <div>
    <Sidebar @side_toggle="sidebar_toggle" :no_profile="this.$store.state.current_profile == null" />
    <main class="main-content">
      <!--      <div @click="test">test</div>-->
      <Topbar @side_toggle="sidebar_toggle" />
      <!-- Modal Account Preferences -->
      <div class="modal fade" id="accountpref-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="
                modal-head
                d-flex
                align-items-center
                justify-content-between
              ">
              <h5 class="modal-title" id="accountpref-modal-label">
                Настройки аккаунта
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.78517 0.244078C1.11061 -0.0813592 1.63824 -0.0813592 1.96368 0.244078L8.04109 6.32149L14.1185 0.244078C14.4439 -0.0813592 14.9716 -0.0813592 15.297 0.244078C15.6225 0.569515 15.6225 1.09715 15.297 1.42259L9.2196 7.5L15.297 13.5774C15.6225 13.9028 15.6225 14.4305 15.297 14.7559C14.9716 15.0814 14.4439 15.0814 14.1185 14.7559L8.04109 8.67851L1.96368 14.7559C1.63824 15.0814 1.11061 15.0814 0.78517 14.7559C0.459733 14.4305 0.459733 13.9028 0.78517 13.5774L6.86258 7.5L0.78517 1.42259C0.459733 1.09715 0.459733 0.569515 0.78517 0.244078Z"
                    fill="#6C757D" />
                </svg>
              </button>
            </div>
            <div class="content-text  p-20">
              <h4>Смена пароля</h4>
              <div class="row mt-4">
                <div class="col-sm-4">
                  <label>Ваш текущий пароль</label>
                  <div class="form-group pass_show">
                    <input type="password" class="form-control" placeholder="Текущий пароль">
                  </div>
                  <label>Новый пароль</label>
                  <div class="form-group pass_show">
                    <input type="password" class="form-control" placeholder="Новый пароль">
                  </div>
                  <label>Новый пароль</label>
                  <div class="form-group pass_show">
                    <input type="password" class="form-control" placeholder="Новый пароль">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Account Preferences end -->
      <MainClear 
        v-if="this.$store.state.current_profile == null">
      </MainClear>
      <Main 
        v-if="this.$store.state.current_profile != null && this.$store.state.current_profile.platform == 'wz'"
        :base64_qr="base64_qr" 
        :is_task_exist="is_task_exist" 
        :authorizations="authorizations" 
        :user="this.user"
        @toggle_task_exist="toggle_task_exist" 
        @qr_updating="qr_updating">
      </Main>
      <MainTG 
        v-if="this.$store.state.current_profile != null && this.$store.state.current_profile.platform == 'tg'"
        :base64_qr="base64_qr" 
        :is_task_exist="is_task_exist" 
        :show_2fa="show_2fa"
        :authorizations="authorizations" 
        :user="this.user"
        @toggle_task_exist="toggle_task_exist" 
        @fa_clear="fa_clear"
        @qr_updating="qr_updating">
      </MainTG>
      <MainAvito 
        v-if="this.$store.state.current_profile != null && this.$store.state.current_profile.platform == 'av'"
        :authorizations="authorizations" 
        :user="this.user">
      </MainAvito>
      <MainSMS 
        v-if="this.$store.state.current_profile != null && this.$store.state.current_profile.platform == 'sms'"
        :authorizations="authorizations" 
        :user="this.user">
      </MainSMS>

      


    </main>
   
  </div>
</template>
<script>
import Sidebar from "./Components/Sidebar.vue";
import Main from "./Components/Main.vue";
import MainTG from "./Components/MainTG.vue";
import MainAvito from "./Components/MainAvito.vue";
import MainClear from "./Components/MainClear.vue";
import MainSMS from "./Components/MainSMS.vue";
import Topbar from "./Components/Topbar.vue";
import ReconnectingWebSocket from 'reconnecting-websocket';
import QRCode from 'qrcode'

export default {
  data() {
    return {
      base64_qr: '',
      is_task_exist: false,
      currentDate: new Date,
      show_2fa: "",
    };
  },
  methods: {
    fa_clear() {
      this.show_2fa = "";
    },
    qr_updating(qr) {
      this.base64_qr = qr;
    },
    sidebar_toggle() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("sidebar-mini");
    },
    createWebSocketConnection() {
      if (typeof window.socket === 'object') {
        window.socket.close();
      }
      console.log('wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all')
      window.socket = new ReconnectingWebSocket(
        'wss://' + window.location.host + '/ws?from_front=1&token=' + this.$store.state.token + '&profile_id=all'
      );
      window.socket.debug = false;
      window.socket.timeoutInterval = 5000;
      window.socket.onmessage = (ev) => {
        let data = JSON.parse(ev.data);
        console.log(data);
        if (data.method === "event") {
          console.log(data)
        }
        if (data.method === "update_qr_code") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            let qr_request_button = document.getElementById('qr_request_button');
            if (qr_request_button && qr_request_button.getAttribute('disabled')) {
              qr_request_button.removeAttribute('disabled');
            }
            if (this.$store.state.current_profile.platform == 'wz') {
              QRCode.toDataURL(data.data)
              .then(url => {
                this.base64_qr = url;
              });
            } else {
              if (!data.data.includes("data:image/png;base64,")) {
                data.data = "data:image/png;base64," + data.data;
              }
              this.base64_qr = data.data;
              console.log(this.base64_qr);
            }
          }

        } else if (data.method === "tg_qr_error") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            // data.data
            this.$swal.fire({
              toast: true,
              position: "top-end",
              icon: "warning",
              title: data.data,
              timerProgressBar: true,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else if (data.method === "2fa") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            this.show_2fa = "2fa";
          }
        } else if (data.method === "update_auth") {
          if ('platform' in data) {
            if (data.platform == "tg") {
              this.$store.commit("set_tg_status", { auth: data.data, profile_id: data.profile_id });
              // if (data.data == 0) {
              //   this.$store.commit("set_wa_status", {data: "close", profile_id: data.profile_id});
              // }
            } else if(data.platform == "wz") {
              this.$store.commit("set_wz_status", { auth: data.data, profile_id: data.profile_id });
            } else if(data.platform == "av") {
              this.$store.commit("set_avito_status", { auth: data.data, profile_id: data.profile_id });
            }
            if (data.profile_id === this.$store.state.current_profile.id) {
              this.base64_qr = '';
              this.is_task_exist = false;
            }
          } 
          
        } else if (data.method === "qr_timeout") {
          if (data.profile_id === this.$store.state.current_profile.id) {
            this.base64_qr = '';
            this.is_task_exist = false;
          }
        } else if (data.method === "update_wa_status") {
          this.$store.commit("set_wa_status", {data: data.data, profile_id: data.profile_id});
          if (data.data === 'close') {
            if(data.platform != "tg") {
              this.$swal.close();
            }
          }
          if (data.data === 'open') {
            if (data.profile_id === this.$store.state.current_profile.id) {
              this.base64_qr = '';
              this.is_task_exist = false;
              if(data.platform == "wz") {
                this.$swal.close();
              }
            }
          }
          if (data.data === 'connecting') {
            if (data.profile_id === this.$store.state.current_profile.id) {
              this.base64_qr = '';
              this.is_task_exist = false;
              if(data.platform == "wz") {
                this.$swal.close();
              }
            }
          }
        }
      };
    },
    toggle_task_exist(data) {
      this.is_task_exist = data;
    },
  },
  props: ["profile", "token", "user", "payments", "tariffs", "container", "today_tasks_count", "authorizations"],
  created() {
    this.$store.commit("set_token", this.token);
    this.$store.commit("set_tariffs", this.tariffs);
    this.$store.commit("set_user", this.user);

    // console.log(this.payments);
    // console.log(this.profile);

    this.$store.commit("set_payments", this.payments);
    this.$store.commit("set_current_profile", this.profile);


    window.addEventListener("message", (event) => {
      // console.log("Полученo:", event.data);
        if (event.origin.indexOf("amocrm.ru") >= 0 || event.origin.indexOf("amocrm.com") >= 0) {
          
          
          if(this.profile && Object.prototype.toString.call(event.data) == '[object String]') {
            console.log("Получен amo acid:", event.data);
            this.$store.commit("set_amocrm", event.data);

            // this.$axios.get("https://" + window.location.host + "/amo/account?acid=" + event.data + "&profile_id=" + this.profile.uuid, {
            //   headers: {
            //     Authorization: this.$store.state.token,
            //   },
            // }).then(res => {
            //   console.log("amo account_id updated");
            // });  
          }
        }
    });

    this.$axios.get(
      "https://" +
      window.location.host +
      "/api/tasks/get/count?profile_id=" +
      this.$store.state.current_profile.uuid,
      {
        headers: {
          Authorization: this.$store.state.token,
        },
      }).then(data => {
        let tt = 0;
        let tc = 0;
        try {
          tt = data.data.today_tasks;
        } catch(e) {
          tt = 0;
        }
        try {
          tc = data.data.pending_tasks;
        } catch(e) {
          tc = 0;
        }

        this.$store.commit("set_counts", {
          today: tt,
          pending: tc
        });
      })


    this.$store.commit("set_wa_status", { data: this.container.wa_status, profile_id: this.container.profile_id });
    this.createWebSocketConnection();
  },
  mounted() {
    const sidebar = document.querySelector(".sidebar");
    if(window.screen.width < 768){
      sidebar.classList.add("sidebar-mini");
    }
    // console.log(process.env.VUE_APP_DOMAIN)
    let Scrollbar;
    if ($(".data-scrollbar").length) {

      let scr = document.querySelector('.data-scrollbar');

      Scrollbar = window.Scrollbar;
      let scrollbar = Scrollbar.init(scr, {
        continuousScrolling: true,
      });

      window.addEventListener("wheel", event => {
        if (scr.firstChild.style.transform !== null && scr.firstChild.style.transform.length > 0 && localStorage['transform'] !== scr.firstChild.style.transform) {
          localStorage['transform'] = scrollbar.scrollTop;
          // console.log(localStorage['transform']);
        }
      });

      if (localStorage['transform']) {
        // console.log(localStorage['transform']);
        scrollbar.scrollTop = localStorage['transform'];
      }

    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('error') === 'already_exists') {
      this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Такой авито аккаунт уже авторизован",
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 3000,
      });
      this.$inertia.visit('/dashboard/' + this.$store.state.current_profile.uuid);
    }
  },
  computed: {},
  components: {
    Sidebar,
    Main,
    Topbar,
    MainTG,
    MainAvito,
    MainClear,
    MainSMS,
},
};
</script>
